import {Listener} from "@/helpers/Listener";

export default {
  state: {
    listener: null,
  },

  actions: {
    openChannel({state, commit}, id) {
      if (!state.listener) {
        commit('setListener', {token: state.api.token, store: this});
      }
      state.listener.subscribe(id);
    },

    closeChannel({state}, id) {
      if (state.listener && !state.loading[id]) state.listener.unsubscribe(id);
    },

    listenPayments({state, commit}) {
      if (!state.listener) {
        commit('setListener', {token: state.api.token, store: this});
      }
      state.listener.listenPaymentsAlerts(state.userId);
    },
  },

  mutations: {
    setListener(state, {token, store}) {
      state.listener = new Listener(token, store)
    },
  },
}