export default {
  state: {
    ais: [],
  },

  getters: {
    aisVersionsBySlug: (state) => (slug) =>  {
      const ai = state.ais.find(ai => ai.slug === slug);
      if (ai) {
        return  ai.versions.reduce((acc, version) => {
          acc[version.slug] = version.name;
          return acc;
        }, {});
      }
      return {};
    },

    isAiActive (state) {
      if (state.currentChatData) {
        return state.currentChatData.ai.is_active && state.currentChatData.version.is_active;
      } else {
        return false;
      }
    }
  },

  actions: {
    async getAis({commit, state}) {
      const ais = await state.api.getAisData();
      await commit('setAis', {ais: ais.data});
      return ais.data;
    },
  },

  mutations: {
    setAis(state, {ais}) {
      state.ais = ais;
    },
  },
}