<template>
  <div class="w-100 mx-5 my-5">
    <div class="fs-5 text-secondary text-center fw-light p-3">Превышен лимит запросов к сервису, нужно немного подождать и мы вас перенаправим обратно</div>
    <div class="fs-3 text-secondary text-center fw-light p-3">{{timer}}</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      timer: 30,
      waitingTime: 30000
    };
  },

  mounted() {
    let timeout = 1000;

    while (timeout < this.waitingTime) {
      setTimeout(() => {
        this.timer -= 1;
        if (this.timer === 1) {
          this.$router.push({name: 'Home'});
        }
      }, timeout);
      timeout += 1000;
    }
  },

}
</script>



<style scoped>

</style>