import {createRouter, createWebHistory} from 'vue-router';

import Login from '@/views/Login.vue'
import Register from '@/views/Register.vue'
import Home from '@/views/Home.vue'
import PasswordReset from "@/views/PasswordReset.vue";
import Account from "@/views/Account.vue";
import PasswordForgot from "@/views/PasswordForgot.vue";
import {Api} from "@/helpers/Api";
import {store} from "@/store/store";
import Favourites from "@/views/Favourites.vue";
import Finances from "@/views/Finances.vue";
import StaticPage from "@/views/StaticPage.vue";
import Waiting from "@/views/Waiting.vue";
import DialogWindow from "@/components/messages/DialogWindow.vue";
import ErrorPage from "@/views/ErrorPage.vue";
import PublicChat from "@/views/PublicChat.vue";
import AisSelect from "@/components/chats/AisSelect.vue";

async function checkAuthorisation () {
  const api = new Api();
  if (store.state.isAuthorized) return store.state.isAuthorized;
  const isAuthorized = await api.tryConnection();
  store.commit('setAuth', isAuthorized);
  return isAuthorized;
}

const routes = [
    {
      path: '/',
      name: 'Root',
      beforeEnter: async (to, from, next) => {
        next({name: 'AisSelect'});
      }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        beforeEnter: async (to, from, next) => {
            const isAuthorized = await checkAuthorisation();
            if (isAuthorized) next({name: 'Home'});
            else next();
        },
    },
    {
        path: '/register',
        name: 'Register',
        component: Register,
        beforeEnter: async (to, from, next) => {
          const isAuthorized = await checkAuthorisation();
          if (isAuthorized) next({name: 'Home'});
            else next();
        },
    },
    {
        path: '/password-forgot',
        name: 'PasswordForgot',
        component: PasswordForgot,
        beforeEnter: async (to, from, next) => {
          const isAuthorized = await checkAuthorisation();
          if (isAuthorized) next({name: 'Home'});
            else next();
        },
    },
    {
        path: '/password-reset',
        name: 'PasswordReset',
        component: PasswordReset,
    },
    {
        path: '/account',
        name: 'Account',
        component: Account,
        beforeEnter: async (to, from, next) => {
          const isAuthorized = await checkAuthorisation();
          if (!isAuthorized) next({name: 'Login'});
            else next();
        },
    },
    {
        path: '/favourites',
        name: 'Favourites',
        component: Favourites,
        beforeEnter: async (to, from, next) => {
          const isAuthorized = await checkAuthorisation();
          if (!isAuthorized) next({name: 'Login'});
            else next();
        },
    },
    {
      path: '/chats',
      component: Home,
      name: 'Home',
      children: [
        {
          path: 'create',
          name: 'AisSelect',
          component: AisSelect,
          props: true,
        },
        {
          path: ':chat_id',
          name: 'Chats',
          component: DialogWindow,
          props: true,
        }
      ],
      beforeEnter: async (to, from, next) => {
        const isAuthorized = await checkAuthorisation();
        if (!isAuthorized) next({ name: 'Login' });
        else next();
      },
    },
    {
        path: '/finances',
        name: 'Finances',
        component: Finances,
        beforeEnter: async (to, from, next) => {
          const isAuthorized = await checkAuthorisation();
          if (!isAuthorized) next({name: 'Login'});
            else next();
        },
    },
    {
        path: '/:page',
        name: 'Page',
        component: StaticPage,
        beforeEnter: async (to, from, next) => {
          const isAuthorized = await checkAuthorisation();
          if(['/policy', '/oferta'].includes(to.path)) next();
            else if (!isAuthorized) next({name: 'Login'});
            else next({name: 'Home'});
        },
    },
    {
      path: '/chats/:id/public',
      name: 'PublicChat',
      component: PublicChat,
      beforeEnter: async (to, from, next) => {
        await checkAuthorisation();
        const result = await store.dispatch('getPublicChat', {id: to.params.id, token: to.query.chat_token});
        if (await result) next();
        else next({name: 'ErrorPage', query: {chat_token: to.query.chat_token}});
      },
    },
    {
        path: '/waiting',
        name: 'Waiting',
        component: Waiting,
    },
    {
        path: '/error',
        name: 'ErrorPage',
        component: ErrorPage,
    },
];

export const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach((to, from, next) => {
  if (to.name === 'Chats' && to.params.chat_id) {
    store.commit('changeChatKey', to.params.chat_id);
  }
  if (to.name === 'Home') {
    store.commit('changeChatKey', null);
  }
  next();
})