<template>
  <AuthorizationTemplate>
    <template #router>
      <router-link class="text-decoration-none" to="/register">
        <button class="btn router__btn" :class="{'router__btn-active': $route.name === 'Register'}">Регистрация</button>
      </router-link>
      <router-link class="text-decoration-none" to="/login">
        <button class="btn router__btn" :class="{'router__btn-active': $route.name === 'Login'}">Вход</button>
      </router-link>
    </template>

    <template #header>
      Восстановить пароль
    </template>

    <template v-if="!passwordSend" #subheader>
      Введите адрес почты
    </template>

    <template #form>
      <form @submit="sendEmail" v-if="!passwordSend">
        <div class="mb-3">
          <label class="form-label mb-0" for="email">Email</label>
          <input class="form-control" type="email" id="email" name="email" v-model="email"
                 autocomplete="email" required>
        </div>
        <button class="btn btn-primary w-100" type="submit">Восстановить</button>
      </form>

      <div v-else class="text-center">
        Письмо с инструкциями для восстановления пароля было отправлено на {{email}}
      </div>
    </template>
  </AuthorizationTemplate>
</template>

<script>
import {Api} from "@/helpers/Api";
import AuthorizationTemplate from "@/components/reusable/AuthorizationTemplate.vue";

export default {
  components: {AuthorizationTemplate},
  data() {
    return {
      email: '',
      errors: {},
      passwordSend: false,
    };
  },

  props: {
    api: Api,
  },

  methods: {
    async sendEmail(e) {
      e.preventDefault();

      if  (await this.api.getResetPasswordUrl(this.email)) {
        this.passwordSend = true;
      }
    }
  }
}
</script>

<style scoped>

</style>