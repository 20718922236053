<template>
  <AuthorizationTemplate>
    <template #router>
      <router-link class="text-decoration-none" to="/register">
        <button class="btn router__btn" :class="{'router__btn-active': $route.name === 'Register'}">Регистрация</button>
      </router-link>
      <router-link class="text-decoration-none" to="/login">
        <button class="btn router__btn" :class="{'router__btn-active': $route.name === 'Login'}">Вход</button>
      </router-link>
    </template>

    <template #header>
      Добро пожаловать!
    </template>

    <template #subheader>
      Войдите в свой аккаунт
    </template>

    <template #form>
      <form @submit="authorize">
        <div class="mb-2">
          <label class="form-label mb-0" for="email">Почта</label>
          <input class="form-control" type="email" id="email" name="email" v-model="email"
                 autocomplete="email" required>
        </div>

        <div class="mb-3">
          <label class="form-label mb-0" for="password">Пароль</label>
          <div class="d-flex align-items-center justify-content-end">
            <input class="form-control" :class="{'is-invalid': errors.message}" :type="passwordType" id="password"
                   name="password" v-model="password" autocomplete="current-password" required>
            <button class="form__password-visible-btn" @click.prevent="switchPasswordType">
              <EyeClosedIcon v-if="passwordType === 'password'" />
              <EyeOpenedIcon v-else />
            </button>
          </div>
          <div class="invalid-feedback">{{ errors.message }}</div>
          <router-link class="small text-decoration-none text-secondary" to="password-forgot">Восстановить пароль</router-link>
        </div>

        <button class="btn btn-primary w-100" type="submit">Войти</button>
      </form>
    </template>
  </AuthorizationTemplate>
</template>

<script>
  import {Api} from "@/helpers/Api";
  import AuthorizationTemplate from "@/components/reusable/AuthorizationTemplate.vue";
  import EyeClosedIcon from "@/components/icons/EyeClosedIcon.vue";
  import EyeOpenedIcon from "@/components/icons/EyeOpenedIcon.vue";

  export default {
    components: {EyeOpenedIcon, EyeClosedIcon, AuthorizationTemplate},
    data() {
      return {
        email: '',
        password: '',
        errors: {},
        passwordType: 'password',
      };
    },

    props: {
      api: Api
    },

    methods: {
      async authorize(e) {
        e.preventDefault();

        if (!await this.api.authorize({
          email: this.email,
          password: this.password
        })) {
          this.errors.message = 'Неправильный Email и/или Пароль';
        } else {
          this.$store.dispatch('getUserData');
          await this.$store.dispatch('getAis');
          await this.$router.push({ name: 'AisSelect' });
        }
      },

      switchPasswordType() {
        this.passwordType = this.passwordType === 'password' ? 'text' : 'password';
      },
    }
  }
</script>

<style scoped>
  .form__password-visible-btn {
    background: none;
    border: none;
    position: absolute;
    padding: 0 14px;
  }

  .was-validated .form-control:invalid, .form-control.is-invalid {
    background-image: none !important;
  }
</style>