import {getMainBranch, getVariants, switchBranch, switchToUpperBranch} from "@/helpers/message_parser";
import {scrollToBottom} from "@/helpers/interface_helpers";

export default {
  state: {
    messagesData: [],
    messages: [],
    variants: [],
    messagesCursors: [],
    loading: [],
    rerenderLoading: [],
    loadingTranslate: [],
    loadingThirdPartyService: [],
    showAlert: [],
    alertMessages: [],
    showMessageAction: [],
    favourites: [],
    favouritesCursor: null,
    newMessageReceived: [],
  },

  actions: {
    async getChatMessages({commit, state}, id) {
      if (!state.messages[id]) {
        const response = await state.api.getChatMessages(id);
        const mainBranch = getMainBranch(await response.data);
        const variants = getVariants(await response.data, await mainBranch);
        const cursor = await response.meta.next_cursor;
        await commit('setChatMessages', {id, messages: mainBranch.reverse(), cursor});
        await commit('setMessagesData', {id, messagesData: response.data});
        await commit('setVariants', {id, variants});
      }
      return state.messages[id];
    },

    async getMoreChatMessages({commit, state}, id) {
      if (state.messagesCursors[id]) {
        const response = await state.api.getChatMessages(id, state.messagesCursors[id]);
        const messages = await getMainBranch(response.data, state.messages[id].at(0).parent_id);
        const cursor = await response.meta.next_cursor;
        await commit('setMessagesData', {id, messagesData: response.data});
        const variants = await getVariants(state.messagesData[id], state.messages[id]);
        await commit('setVariants', {id, variants});
        await commit('setChatMessages', {id, messages: messages.reverse(), cursor});
        return messages.at(-1).id;
      }
    },

    async getFavourites({commit, state}, ai = null) {
      const response = await state.api.getFavourites({ai, cursor: null});
      await commit('setFavourites', {
        favourites: await response.data.map(({message}) => message),
        cursor: await response.meta.next_cursor,
      });
    },

    async loadMoreFavourites({commit, state}, ai = null) {
      if (state.favouritesCursor) {
        const response = await state.api.getFavourites({ai, cursor: state.favouritesCursor});
        await commit('addFavourites', {
          favourites: response.data.map(({message}) => message),
          cursor: response.meta.next_cursor,
        });
      }
    },

    async sendMessage({state, commit}, {id, message}) {
      const response = await state.api.storeMessage(id, message);
      if (await response.data) {
        await commit('addChatMessage', {id, message: response.data});
        await commit('addChatMessageData', {id, messageData: response.data});
      }
      return await response.data ?? response;
    },

    async resolveMessage({state, commit}, {id, data}) {
      if (data.message_id && Number(state.currentChat) !== Number(id)) {
        const newMessage = await state.api.getMessage(id, data.message_id);
        if (await newMessage.data.role !== 'user') {
          await commit('setLoading', {value: false, id});
          await this.dispatch('closeChannel', id);
          await commit('setNewMessageReceived', {id, value: true});
        }
      }

      if (data.message_id && state.messages[id]) {
        if (!state.messages[id].find(({id}) => Number(data.message_id) === Number(id))) {
          const newMessage = await state.api.getMessage(id, data.message_id);
          await commit('addChatMessageData', {id, messageData: newMessage.data});

          const currentVariants = state.variants[id].find(v => {
            return Number(v.parentId) === Number(newMessage.data.parent_id);
          });
          await commit('addVariant', {
            chatId: id,
            variantsIndex: state.variants[id].indexOf(currentVariants),
            newMessageId: newMessage.data.id,
            parentId: newMessage.data.parent_id,
          });

          await state.api.updateMessage(id, data.message_id, { message_seen: true });
          await commit('setLoading', {value: false, id});
          await commit('setThirdPartyServiceLoading', {value: false, id});

          if (await newMessage.data.parent_id === state.messages[id].at(-1).parent_id) {
            const prevBranchMessage = state.messages[id].at(-1);
            state.messages[id] = switchBranch(state.messages[id], state.messagesData[id], {
              messageId: prevBranchMessage.id,
              newMessageId: newMessage.data.id,
            });
          } else if(currentVariants) {
            state.messages[id] = switchToUpperBranch(state.messages[id], state.messagesData[id], {
              messageId: newMessage.data.parent_id,
              newMessageId: newMessage.data.id,
            });
          } else {
            commit('addChatMessage', {id, message: newMessage.data});
          }

          await this.dispatch('getUserData');
          if (state.currentChat) {
            if (Number(state.currentChat) === Number(id)) {
              await scrollToBottom();
            }
          }
        }
      }

      if (data.error) {
        commit('setLoading', {value: false, id});
        commit('setThirdPartyServiceLoading', {value: false, id});
        commit('setAlert', {value: true, id, message: 'Не удалось отправить сообщение'})
        await scrollToBottom();
        setTimeout(() => commit('setAlert', {value: false, id}), 7000);
      }
    },

    resolveWaitingNotification({commit}, {id}) {
      commit('setLoading', {value: false, id});
      commit('setThirdPartyServiceLoading', {value: true, id});
    },

    switchBranch({state, commit}, params) {
      state.messages[params.chatId] = switchBranch(state.messages[params.chatId], state.messagesData[params.chatId], params);
      commit('changeCurrentVariant', params);
    },

    async loadSearchingMessage({state}, {chatId, messageId}) {
      let isMessageLoaded = await state.messages[chatId].find((message) => Number(message.id) === Number(messageId));
      while (!isMessageLoaded && state.messagesCursors[chatId]) {
        await this.dispatch('getMoreChatMessages', chatId);
        isMessageLoaded = await state.messages[chatId].find((message) => Number(message.id) === Number(messageId));
      }
    },

    async storeAction({state, commit}, params) {
      const response = await state.api.storeAction(params);
      await commit('addAction', {...params, action: response.data});
      if (params.action === 'favourite') {
        state.showMessageAction[params.chatId] = await 'Сообщение добавлено в избранное';
        await setTimeout(() => {
          state.showMessageAction[params.chatId] = null;
        }, 4000);
      }
      return await response.data ?? response;
    },

    async deleteAction({state, commit}, params) {
      await state.api.deleteAction(params);
      await commit('removeAction', params);
      if (params.removeAction === 'favourite') {
        state.showMessageAction[params.chatId] = await 'Сообщение удалено из избранных';
        await setTimeout(() => {
          state.showMessageAction[params.chatId] = null;
        }, 4000);
      }
    },


  },

  mutations: {
    setChatMessages(state, {id, messages, cursor}) {
      if (!state.messages[id]) {
        state.messages[id] = messages;
      } else {
        state.messages[id].unshift(...messages);
      }
      state.messagesCursors[id] = cursor;
    },

    deleteChatMessages(state, id) {
      state.messages[id] = null;
      state.messagesData[id] = null;
    },

    addChatMessage(state, {id, message}) {
      if (!state.messages[id].find(m => m.id === message.id))
        state.messages[id].push(message);
    },

    setMessagesData(state, {id, messagesData}) {
      if (!state.messagesData[id]) {
        state.messagesData[id] = messagesData;
      } else {
        state.messagesData[id].unshift(...messagesData);
      }
    },

    addChatMessageData(state, {id, messageData}) {
      if (!state.messagesData[id].find(m => m.id === messageData.id))
        state.messagesData[id].push(messageData);
    },

    setVariants(state, {id, variants}) {
      state.variants[id] = variants;
    },

    addVariant(state, {chatId, variantsIndex, newMessageId, parentId}) {
      if (state.variants[chatId][variantsIndex]) {
        if (!state.variants[chatId][variantsIndex].ids.find(id => Number(id) === Number(newMessageId))) {
          state.variants[chatId][variantsIndex].ids.push(newMessageId);
          state.variants[chatId][variantsIndex].current = newMessageId;
        }
      } else {
        state.variants[chatId].push({ids: [newMessageId], current: newMessageId, parentId});
      }
    },

    changeCurrentVariant(state, {chatId, variantsIndex, newMessageId}) {
      state.variants[chatId][variantsIndex].current = newMessageId;
    },

    addAction(state, {messageId, chatId, action, removeAction}) {
      if (state.messages[chatId]) {
        const key = Object.keys(state.messages[chatId]).find(key => state.messages[chatId][key].id === messageId);
        state.messages[chatId][key].actions.push(action);
        if (removeAction) {
          state.messages[chatId][key].actions = state.messages[chatId][key].actions.filter(action => action.type !== removeAction);
        }
      }
    },

    removeAction(state, {messageId, chatId, removeAction}) {
      if (state.messages[chatId]) {
        const key = Object.keys(state.messages[chatId]).find(key => state.messages[chatId][key].id === messageId);
        state.messages[chatId][key].actions = state.messages[chatId][key].actions.filter(action => action.type !== removeAction);
      }
      if (removeAction === 'favourite') {
        state.favourites = state.favourites.filter(message => message.id !== messageId);
      }
    },

    setFavourites(state, {favourites, cursor}) {
      state.favourites = favourites;
      state.favouritesCursor = cursor;
    },

    addFavourites(state, {favourites, cursor}) {
      state.favourites.push(...favourites);
      state.favouritesCursor = cursor;
    },

    setLoading(state, {value, id}) {
      state.loading[id] = value;
    },

    setRerenderLoading(state, {value, id}) {
      state.rerenderLoading[id] = value;
    },

    setThirdPartyServiceLoading(state, {id, value}) {
      state.loadingThirdPartyService[id] = value;
    },

    setLoadingTranslate(state, {value, id}) {
      state.loadingTranslate[id] = value;
    },

    setAlert(state, {value, id, message}) {
      state.showAlert[id] = value;
      state.alertMessages[id] = message;
    },

    setNewMessageReceived(state, {id, value}) {
      const chat = state.chats.data.find(chat => chat.id === id);
      chat.has_not_seen_messages = value;
    }
  },
}