<template>
  <div class="d-flex flex-wrap align-content-start">

    <AccountInfo :api="api" />

    <AccountSettings :api="api" :username="username" />
  </div>
</template>

<script>
  import {Api} from "@/helpers/Api";
  import AccountSettings from "@/components/account/AccountSettings.vue";
  import AccountInfo from "@/components/account/AccountInfo.vue";

  export default {
    components: { AccountSettings, AccountInfo },

    props: {
      api: Api,
      username: String,
    },
  }
</script>

<style scoped>

</style>