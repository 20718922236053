import {createStore} from "vuex";
import {Api} from "@/helpers/Api";

import user_settings from "@/store/user_settings";
import ais from "@/store/ais";
import payments from "@/store/payments";
import listener from "@/store/listener";
import messages from "@/store/messages";
import chats from "@/store/chats";
import files from "@/store/files";
export const store = createStore({
  state: {
    api: new Api(),
    ...user_settings.state,
    ...ais.state,
    ...payments.state,
    ...listener.state,
    ...messages.state,
    ...chats.state,
    ...files.state,
  },

  actions: {
    ...user_settings.actions,
    ...ais.actions,
    ...payments.actions,
    ...listener.actions,
    ...messages.actions,
    ...chats.actions,
    ...files.actions,
  },

  mutations: {
    ...user_settings.mutations,
    ...ais.mutations,
    ...payments.mutations,
    ...listener.mutations,
    ...messages.mutations,
    ...chats.mutations,
    ...files.mutations,
  },

  getters: {
    ...ais.getters,
  }
});