<template>
  <div class=" d-flex flex-column w-100 my-4 overflow-auto flex-grow-1">
    <div class="public-chat rounded overflow-y-scroll p-3 pb-5">
      <Messages :messages="messages" placement="public_chat" :chat-id="chat.id" />
    </div>
  </div>

  <button v-if="isAuthorized"
          @click="copyChat"
          :disabled="isCopyButtonDisabled"
    class="position-absolute bottom-0 start-50 translate-middle-x btn btn-primary mb-5 shadow-lg px-3"
    style="z-index:999;">
    Скопировать чат
  </button>
  <button v-else
          @click="$router.push({name: 'Register'})"
          class="position-absolute bottom-0 start-50 translate-middle-x btn btn-primary mb-5 shadow-lg px-3"
          style="z-index:999;">
    Зарегистрироваться
  </button>

  <div v-show="isCopyErrorShown" class="alert alert-danger position-absolute end-0 mx-4 mt-3 chat-copy-error-alert" role="alert">Не удалось скопировать чат. Попробуйте позже еще раз.</div>
</template>

<script>
  import Messages from "@/components/messages/MessagesList.vue";
  import {scrollToBottom} from "@/helpers/interface_helpers";
  import {router} from "@/router";

  export default {
    components: {Messages},

    data() {
      return {
        isCopyButtonDisabled: false,
        isCopyErrorShown: false,
      }
    },

    computed: {
      messages() {
        return this.$store.state.messages[this.$store.state.currentChat];
      },

      chat() {
        return this.$store.state.publicChat;
      },

      isAuthorized() {
        return this.$store.state.isAuthorized;
      }
    },

    mounted() {
      scrollToBottom('.public-chat');
    },

    methods: {
      router() {
        return router
      },
      async copyChat() {
        this.isCopyButtonDisabled = true;
        const copyChatId = await this.$store.dispatch('addChatCopy', {id: this.chat.id, token: this.$route.query.chat_token});
        if (copyChatId) {
          await this.$router.push({name: 'Chats', params: {chat_id: copyChatId}});
        } else {
          this.isCopyButtonDisabled = false;
          this.isCopyErrorShown = true;
          setTimeout(() => {
            this.isCopyErrorShown = false;
          }, 3000);
        }
      },
    }
  }
</script>

<style scoped>
  .public-chat {
    background-color: var(--block-bg-color);
  }

  .chat-copy-error-alert {
    animation: ani 0.3s forwards;
  }

  @keyframes ani {
    0% {opacity: 0;}
    100% {opacity: 1;}
  }
</style>