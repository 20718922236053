<template>
  <AuthorizationTemplate v-if="!isPasswordReset">
    <template #router>
      <router-link class="text-decoration-none" to="/register">
        <button class="btn router__btn" :class="{'router__btn-active': $route.name === 'Register'}">Регистрация</button>
      </router-link>
      <router-link class="text-decoration-none" to="/login">
        <button class="btn router__btn" :class="{'router__btn-active': $route.name === 'Login'}">Вход</button>
      </router-link>
    </template>

    <template #header v-if="isAvailable">
      Восстановить пароль
    </template>

    <template #header v-else>
      Ссылка для восстановления пароля некорректна.
    </template>

    <template #subheader v-if="isAvailable">
      Придумайте новый пароль
    </template>

    <template #form v-if="isAvailable">
      <form @submit="resetPassword">
        <div class="mb-2">
          <label class="form-label mb-0" for="password">Новый пароль</label>
          <input class="form-control" :class="{'is-invalid': errors.message}" type="password" id="password"
                 name="password" v-model="password" autocomplete="new-password" required>
        </div>

        <div class="mb-3">
          <label class="form-label mb-0" for="password">Повторите пароль</label>
          <input class="form-control" :class="{'is-invalid': errors.message}" type="password" id="passwordConfirm"
                 name="passwordConfirm" v-model="passwordConfirm" autocomplete="new-password" required @input="validateConfirmation">
          <div class="invalid-feedback">{{ errors.message }}</div>
        </div>
        <button class="btn btn-primary w-100" type="submit">Создать новый пароль</button>
      </form>
    </template>
  </AuthorizationTemplate>

  <AuthorizationTemplate v-else>
    <template #header>
      Успешно
    </template>

    <template #subheader>
      Войдите в аккаунт с новым паролем
    </template>

    <template #form>
      <button class="btn btn-primary w-100" type="submit" @click="login">Войти</button>
    </template>
  </AuthorizationTemplate>
</template>

<script>
import {Api} from "@/helpers/Api";
import AuthorizationTemplate from "@/components/reusable/AuthorizationTemplate.vue";

export default {
  components: {AuthorizationTemplate},
  data() {
    return {
      password: '',
      passwordConfirm: '',
      errors: {},
      isPasswordReset: false,
    };
  },

  props: {
    api: Api,
  },

  computed: {
    isAvailable() {
      return this.$route.query.email && this.$route.query.token;
    }
  },

  methods: {
    async resetPassword(e) {
      e.preventDefault();

      if (! this.validateConfirmation()) return;

      const response = await this.api.resetPassword({
        email: this.$route.query.email,
        token: this.$route.query.token,
        password: this.password,
      });

      // eslint-disable-next-line no-prototype-builtins
      if (response.hasOwnProperty('error')) {
        this.errors.message = await response.error === '' ? 'Ошибка при валидации данных' : await response.error;
      } else {
        this.isPasswordReset = true;
      }
    },

    async login() {
      await this.api.authorize({password: this.password, email: this.$route.query.email});
      await this.$router.push({ name: 'AisSelect' });
    },

    validateConfirmation() {
      if (this.password !== this.passwordConfirm) {
        this.errors.message = 'Пароли не совпадают';
        return false;
      } else {
        this.errors.message = null;
      }
      return true;
    }

  }
}
</script>
<style scoped>

</style>