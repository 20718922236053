<template>
    <div class="home__dialog-view">
        <router-view :key="$route.path" :chatId="$route.params.chat_id" class="app-dialog-view w-100 overflow-y-auto" @new-message-received="setScrollTop"></router-view>
    </div>
</template>

<script>
export default {
  methods: {
    setScrollTop(value) {
      if (this.$refs.chatList) {
        this.$refs.chatList.setScrollTop(value);
      }
    }
  },

}
</script>

<style scoped>
  .home__dialog-view {
    display: flex;
    overflow: auto;
    flex-grow: 1;
  }
</style>