<template>
  <div class="static-page d-flex flex-column mb-4 overflow-auto rounded-2" :class="{
    'w-100': isAuthorized,
    'w-75': !isAuthorized,
  }">
    <div class="static-page__container rounded-2" :class="{
      'overflow-y-scroll': isAuthorized,
    }">
      <div class="static-page__title">{{title}}</div>

      <div class="static-page__content" v-html="content"></div>
    </div>
  </div>
</template>

<script>
import {Api} from "@/helpers/Api";
import SectionTitle from "@/components/reusable/SectionTitle.vue";

export default {
  components: {SectionTitle},
  data() {
    return {
      title: '',
      content: '',
    }
  },

  props: {
    api: Api,
    isAuthorized: Boolean,
  },

  async beforeMount() {
    const response = await this.api.getPage(this.$route.params.page);
    this.title = await response.data.title;
    this.content = await response.data.content;
  }
}
</script>

<style scoped>
  .static-page {
    margin-top: 24px;
    box-shadow: var(--static-page-shadow);
  }

  .static-page__container {
    overflow-y: auto;
    padding: 24px 40px 14px 40px;
    background-color: var(--block-bg-color);
  }

  .static-page__title {
    font-size: 32px;
    border-bottom: 1px solid var(--border-color);
    margin-bottom: 20px;
    padding-bottom: 20px;
    line-height: 44px;
  }
</style>