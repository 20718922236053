<template>
  <div class="w-100 flex-column d-flex overflow-auto flex-grow-1">
    <div class="finances__balance-container d-flex flex-nowrap justify-content-between rounded mt-4 p-4">
      <div class="d-flex gap-2 align-items-center">
        <BalanceTitle :balance="balance" />
      </div>

      <div class="d-flex d-flex flex-nowrap gap-4 align-items-center">
        <AutoPay :api="api"/>
        <Pay :api="api"/>
      </div>
    </div>

    <Transactions :api="api" />

  </div>
</template>

<script>
  import Pay from "@/components/finances/Pay.vue";
  import AutoPay from "@/components/finances/AutoPay.vue";
  import {Api} from "@/helpers/Api";
  import Transactions from "@/components/finances/Transactions.vue";
  import BalanceTitle from "@/components/icons/BalanceTitle.vue";

  export default {
    components: {BalanceTitle, Transactions, AutoPay, Pay },

    props: {
      api: Api,
    },

    computed: {
      balance() {
        return this.$store.state.balance;
      },

      isAutopay() {
        return this.$store.state.isAutopay;
      },
    }
  }
</script>

<style scoped>
.finances__balance-container {
  background-color: var(--block-bg-color);
}
</style>