export default {
  state: {
    files: {
      image: [],
      audio: [],
      video: [],
      code_interpreter: [],
      file_search: [],
    },
  },

  actions: {
    async uploadFiles({state, commit}, {type, files, id, collection, config}) {
      if (type === 'code_interpreter' || type === 'file_search') {
        const data = new FormData();

        data.append('collection', collection);

        files.forEach((file) => {
          data.append('files[]', file);
        });

        const response = await state.api.uploadFilesToChat({id, data, config});
        if (response) {
          files = await response.data;
          await commit('addUuidToFiles', {type, files});

        } else {
          return false;
        }
      } else {
        await commit('addFiles', {type, files});
      }
      return true;
    },

    async deleteFile({state, commit}, {type, name, uuid, id}) {
      if (type === 'code_interpreter' || type === 'file_search') {
        const response = await state.api.deleteFileFromChat(id, uuid);
        if (!response) {
          return false;
        }
      }
      commit('removeFile', {type, name});
      return true;
    }
  },

  mutations: {
    addFiles(state, {type, files}) {
      this.state.files[type].push(...files);
    },

    addUuidToFiles(state, {type, files}) {
      files.forEach((file) => {
        this.state.files[type] = this.state.files[type].map((loadedFile) => {
          if (loadedFile.name === file.file_name) {
            return file;
          } else {
            return loadedFile;
          }
        });
      });
    },

    removeFile(state, {type, name}) {
      this.state.files[type] = state.files[type].filter(file => file.name !== name);
    },

    clearFiles(state) {
      Object.keys(state.files).forEach((type) => {
        state.files[type] = [];
      });
    },

    clearTemporaryFiles(state) {
      state.files.image = state.files.image.filter(file => file.uuid);
      state.files.audio = state.files.audio.filter(file => file.uuid);
      state.files.video = state.files.video.filter(file => file.uuid);
      state.files.code_interpreter = state.files.code_interpreter.filter(file => file.uuid);
      state.files.file_search = state.files.file_search.filter(file => file.uuid);
    }
  }
}