<template>
  <AuthorizationTemplate>
    <template #router>
      <router-link class="text-decoration-none" to="/register">
        <button class="btn router__btn" :class="{'router__btn-active': $route.name === 'Register'}">Регистрация</button>
      </router-link>
      <router-link class="text-decoration-none" to="/login">
        <button class="btn router__btn" :class="{'router__btn-active': $route.name === 'Login'}">Вход</button>
      </router-link>
    </template>

    <template #header>
      Добро пожаловать!
    </template>

    <template #subheader>
      Пожалуйста, зарегистрируйтесь
    </template>

    <template #form>
      <form @submit="register">
        <div class="mb-2">
          <label class="form-label mb-0" for="email">Почта</label>
          <input class="form-control" :class="{'is-invalid': errors.email}" type="email" id="email" name="email" v-model="email"
                 autocomplete="email" required @input="validateEmail">
          <div class="small text-danger">{{ errors.email }}</div>
        </div>

        <div class="mb-2">
          <label class="form-label mb-0" for="password">Пароль</label>
          <div class="d-flex align-items-center justify-content-end">
            <input class="form-control" :class="{'is-invalid': errors.password}" :type="passwordType" id="password"
                   name="password" v-model="password" autocomplete="new-password" required @input="validateConfirmation">
            <button class="form__password-visible-btn" @click.prevent="switchPasswordType">
              <EyeClosedIcon v-if="passwordType === 'password'" />
              <EyeOpenedIcon v-else />
            </button>
          </div>
          <div class="small text-danger">{{ errors.password }}</div>
        </div>

        <div class="mb-3">
          <label class="form-label mb-0" for="passwordConfirm">Повторите пароль</label>
          <div class="d-flex align-items-center justify-content-end">
            <input class="form-control" :class="{'is-invalid': errors.passwordConfirm}" :type="passwordConfirmType" id="passwordConfirm"
                   name="passwordConfirm" v-model="passwordConfirm" autocomplete="new-password" required @input="validateConfirmation">
            <button class="form__password-visible-btn" @click.prevent="switchPasswordConfirmType">
              <EyeClosedIcon v-if="passwordConfirmType === 'password'" />
              <EyeOpenedIcon v-else />
            </button>
          </div>
          <div class="small text-danger">{{ errors.passwordConfirm }}</div>
        </div>

        <button class="btn btn-primary w-100" type="submit">Зарегистрироваться</button>
      </form>
    </template>
  </AuthorizationTemplate>
</template>

<script>
  import {Api} from "@/helpers/Api";
  import AuthorizationTemplate from "@/components/reusable/AuthorizationTemplate.vue";
  import EyeClosedIcon from "@/components/icons/EyeClosedIcon.vue";
  import EyeOpenedIcon from "@/components/icons/EyeOpenedIcon.vue";

  export default {
    components: {EyeOpenedIcon, EyeClosedIcon, AuthorizationTemplate},
    data() {
      return {
        email: '',
        password: '',
        name: '',
        passwordConfirm: '',
        errors: {},
        passwordType: 'password',
        passwordConfirmType: 'password',
      };
    },

    props: {
      api: Api
    },

    methods: {
      async register(e) {
        e.preventDefault();

        if (this.errors.password || this.errors.passwordConfirm || this.errors.email) return;

        const response = await this.api.registrate({
          name: this.name,
          email: this.email,
          password: this.password
        });

        if (await response.hasOwnProperty('error')) {
          this.errors.message = await response.error === '' ? 'Ошибка при валидации данных' : await response.error;
        } else {
          this.$store.dispatch('getUserData');
          await this.$store.dispatch('getAis');
          await this.$router.push({ name: 'AisSelect' });
        }
      },

      validateEmail() {
        var emailCheck = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!emailCheck.test(String(this.email).toLowerCase())) {
          console.log('not')
          this.errors.email = 'Введите валидный email';
        } else {
          this.errors.email = null;
        }
      },

      validateConfirmation() {
        if (this.password.length < 8) {
          this.errors.password = 'Пароль должен быть не меннее 8 символов';
        } else {
          this.errors.password = null;
        }

        if (this.password !== this.passwordConfirm && this.passwordConfirm.length >= this.password.length) {
          this.errors.passwordConfirm = 'Пароли не совпадают';
        } else {
          this.errors.passwordConfirm = null;
        }
      },

      switchPasswordType() {
        this.passwordType = this.passwordType === 'password' ? 'text' : 'password';
      },

      switchPasswordConfirmType() {
        this.passwordConfirmType = this.passwordConfirmType === 'password' ? 'text' : 'password';
      },
    },
  }
</script>

<style scoped>
  .form__password-visible-btn {
    background: none;
    border: none;
    position: absolute;
    padding: 0 14px;
  }

  .was-validated .form-control:invalid, .form-control.is-invalid {
    background-image: none !important;
  }
</style>