import {getMainBranch, getVariants} from "@/helpers/message_parser";

export default {
  state: {
    chats: {
      data: [],
      meta: {},
    },
    publicChat: {},
    currentAi: null,
    selectModeOn: false,
    currentChat: null,
    currentChatData: null,
  },

  actions: {
    async getChats({commit, state}) {
      const cursor = state.chats.meta ? state.chats.meta.next_cursor : null;
      let response = await state.api.getChatsData(cursor);
      if (response) {
        await commit('setChats', {chats: response, reset: !cursor});
      }

      return state.chats;
    },

    async getAllChats({commit, state}) {
      while(state.chats.meta.next_cursor) {
        let response = await state.api.getChatsData(state.chats.meta.next_cursor);
        if (response) {
          await commit('setChats', {chats: response, reset: false});
        }
      }
    },

    async getChat({state, commit}, id) {
      const response = await state.api.getChat(id);
      if (!response) {
        commit('changeChatKey', null);
      } else {
        commit('setCurrentChatData', response.data);
        return response;
      }
    },

    async storeChat({commit, state}, chat) {
      const newChatResponse = await state.api.storeChat(chat);
      const allChatsResponse = await state.api.getChatsData(newChatResponse.data.ai.slug);
      await commit('setChats', {chats: allChatsResponse, reset: true, model: chat.model});
      return await newChatResponse.data;
    },

    async addChatCopy({commit, state}, {id, token}) {
      const response = await state.api.createChatCopy(id, token);
      if (response) {
        const chatCopy = await response.data;
        const allChatsResponse = await state.api.getChatsData(chatCopy.ai.slug);
        await commit('setChats', {chats: allChatsResponse, reset: true, model: chatCopy.ai.slug});
        return chatCopy.id;
      }
      return response;
    },

    async deleteChat({state, commit}, {id}) {
      await state.api.deleteChat(id);
      await commit('removeChat', {id});
      if (state.currentChat) {
        if (Number(state.currentChat) === Number(id)) {
          commit('changeChatKey', null);
          commit('setCurrentChatData', null);
        }
      }
    },

    async deleteChats({state, commit}, chatIds) {
      const response = await state.api.deleteChats(chatIds);
      if (response) {
        commit('removeChats', chatIds);
      }
    },

    async updateChatName({state, commit}, chat) {
      const response = await state.api.updateChat(chat);
      if (response) {
        commit('resetChatName', chat);
      }
      return response;
    },
    async getPublicChat({state, commit}, {id, token}) {
      const response = await state.api.getPublicChatMessages(id, token);
      if (response) {
        state.currentChat = id;
        state.publicChat = await response.chat;

        const mainBranch = getMainBranch(await response.messages);
        const variants = getVariants(await response.messages, await mainBranch);
        await commit('setChatMessages', {id, messages: mainBranch.reverse(), cursor: null});
        await commit('setMessagesData', {id, messagesData: response.messages});
        await commit('setVariants', {id, variants});
      }
      return response;
    },

    async generateToken({state}, {id}) {
      const response = await state.api.addPublicLinkTokenToChat(id);
      const chat = state.chats.data.find((chat) => Number(chat.id) === Number(id));
      chat.public_link_token = await response.data.public_link_token;
    },

    async loadSearchingChat({state}, {id}) {
      let isChatLoaded = await state.chats ? state.chats.data.find((chat) => Number(chat.id) === Number(id)) : false;
      while (!isChatLoaded) {
        const cursor = await state.chats ? state.chats.meta.next_cursor : null;
        await this.dispatch('getChats');
        isChatLoaded = await state.chats.data.find((chat) => Number(chat.id) === Number(id));
        if (await state.chats.meta.next_cursor === null) {
          isChatLoaded = await true;
        }
      }
    },

  },

  mutations: {
    setChats(state, {chats, reset}) {
      if (reset) {
        state.chats = chats
      } else {
        state.chats.data = [...state.chats.data, ...chats.data];
        state.chats.meta = chats.meta;
      }
    },

    removeChat(state, {id}) {
      state.chats.data = state.chats.data.filter((chat) => chat.id !== id);
    },

    removeChats(state, ids) {
      state.chats.data = state.chats.data.filter((chat) => !ids.includes(chat.id));
    },

    changeChatKey(state, id) {
      state.currentChat = id;
    },

    setCurrentChatData(state, chat) {
      state.currentChatData = chat;
    },

    setChatFirst(state, {id}) {
      const requiredChat = state.chats.data.find(chat => Number(id) === Number(chat.id));
      const otherChats = state.chats.data.filter(chat => Number(id) !== Number(chat.id));
      state.chats.data = [requiredChat, ...otherChats];
    },

    resetChatName(state, {id, name}) {
      state.chats.data.forEach((chat) => {
        if (chat.id === id) {
          chat.name = name;
        }
      });
    },

    resetChatAiVersion(state, {id, slug}) {
      state.chats.data.forEach((chat) => {
        if (chat.id === id) {
          chat.version.slug = slug;
        }
      });
    },

    setSelectMode(state, value) {
      state.selectModeOn = value;
    },

    setChatsDeleteStatus(state, {chats, value}) {
      const deleteIds = chats.map(chat => chat.id);

      state.chats.data.forEach((chat) => {
        if (deleteIds.includes(chat.id)) {
          chat.isDelete = value;
        }
      });
    }
  },
}