import {Api} from "@/helpers/Api";

export default {
  state: {
    username: null,
    userId: null,
    balance: null,
    isAutopay: null,
    name: null,
    isAuthorized: null,
    appTheme: localStorage.getItem('neyro_app_theme') ?? 'light',
  },

  actions: {
    async cleanState({state}) {
      state.isAuthorized = false;
      state.chats = [];
      state.api = new Api();
      state.listener = null;
    },

    async getUserData({commit, state}) {
      const response = await state.api.getUserData();
      if (await response) {
        commit('setUsername', response.data.email);
        commit('setName', response.data.name);
        commit('setUserBalance', response.data.balance);
        commit('setUserAutopay', response.data.is_autopay);
        commit('setUserId', response.data.id);
      }
    },

  },

  mutations: {
    setAuth(state, isAuthorized) {
      state.isAuthorized = isAuthorized;
    },

    setUsername(state, username) {
      state.username = username;
    },

    setUserBalance(state, balance) {
      state.balance = balance;
    },

    setUserAutopay(state, isAutopay) {
      state.isAutopay = isAutopay;
    },

    setName(state, name) {
      state.name = name;
    },

    setUserId(state, id) {
      state.userId = id;
    },

    setAppTheme(state, theme) {
      state.appTheme = theme;
      localStorage.setItem('neyro_app_theme', theme);
    },

  },
}