<template>
  <div class="favourites d-flex flex-column w-100 mb-4 overflow-auto flex-grow-1">
    <SectionTitle title="Избранные сообщения" />

    <div class="favourites__container rounded overflow-y-scroll p-3" v-if="favourites.length > 0">

        <Messages :messages="favourites" placement="favourites" />

      <div class="pt-1 pb-4">
        <button type="button" class="btn btn-secondary" :disabled="!cursor" @click="loadMoreFavourites">Загрузить еще сообщения</button>
      </div>

    </div>
    <div v-else-if="selectedAi !== null" class="fs-5 text-secondary text-center fw-light p-5" v-text="emptyFavouritesOfAiMessage"></div>
    <div v-else class="fs-5 text-secondary text-center fw-light p-5" v-text="emptyFavouritesMessage"></div>
  </div>
</template>

<script>
   import {Api} from "@/helpers/Api";
   import Messages from "@/components/messages/MessagesList.vue";
   import SectionTitle from "@/components/reusable/SectionTitle.vue";

   export default {
     components: {
       SectionTitle,
       Messages,
     },

     data() {
       return {
         emptyFavouritesMessage: null,
         emptyFavouritesOfAiMessage: null,
         isOriginal: [],
         selectedAi: null,
       }
     },

     props: {
       api: Api,
     },

     computed: {
       favourites() {
          return this.$store.state.favourites;
       },

       cursor() {
         return this.$store.state.favouritesCursor;
       },

       ais() {
         return this.$store.state.ais;
       }
     },

     async beforeMount() {
       await this.$store.dispatch('getFavourites');
       this.emptyFavouritesMessage = await 'У вас нет сообщений добавленных в избранное';
       this.emptyFavouritesOfAiMessage = await 'У вас нет сообщений добавленных в избранное данной нейросети';
     },

     methods: {
       async loadMoreFavourites() {
         await this.$store.dispatch('loadMoreFavourites', this.selectedAi);
       },

       filterByAi() {
         this.$store.dispatch('getFavourites', this.selectedAi);
       },
     },
   }
</script>

<style scoped>
.favourites__container {
  background-color: var(--block-bg-color);
}
</style>