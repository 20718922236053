export default {
  state: {
    paymentNotifications: {
      success: null,
      error: null,
    },
  },

  actions: {
    showPaymentNotification({commit}, data) {
      if (data.error) {
        commit('setPaymentNotificationError', data.error);
        setTimeout(() => commit('setPaymentNotificationError', null), 7000);
      } else {
        commit('setUserBalance', data.balance);
        if (data.autopay) {
          commit('setUserAutopay', true);
        }
        commit('setPaymentNotificationSuccess', `Баланс пополнен на ${data.amount}руб.`);
        setTimeout(() => commit('setPaymentNotificationSuccess', null), 7000);
      }
    },
  },

  mutations: {
    setPaymentNotificationSuccess(state, message) {
      state.paymentNotifications.success = message;
    },

    setPaymentNotificationError(state, message) {
      state.paymentNotifications.error = message;
    },
  },
}