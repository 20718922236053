<template>
  <div class="w-100 mx-5 my-5">
    <div class="fs-5 text-secondary text-center fw-light p-3">
      {{alert}}
      <br>
      <router-link to="/chats">Вернуться на главную</router-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      alert: this.$route.query.chat_token ?
        'Кажется ссылка на чат некорректна' :
        'Кажется такой сраницы не существует.',
    }
  }
}
</script>
<style scoped>

</style>